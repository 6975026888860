<template>
  <div class="gls">
    <div class="text-title">
      <i>三维GIS+BIM</i>
      <p>
        团队拥有向前可兼容，向后可拓展的高效敏捷开发平台，可快速效响应业务需求，
        快速开发，快递迭代，最优质的服务客户
      </p>
    </div>
    <div class="video w">
      <video-player class="video-player vjs-custom-skin size-box" ref="videoPlayer" :playsinline="true" :options="playerOptions" />
      <div class="video-title">{{title?title:''}}</div>
    </div>
    <!-- 技术特点 -->
    <div class="technical">
      <div class="text-title">
        <i>技术特点</i>
        <p>实现“三维场景可视化+GIS+CIM+BIM”等一系列多场景应用</p>
      </div>
      <div class="technical-list w">
        <span>
          <div class="title">大图片切片加载</div>
          <div class="img-box">
            <img src="../assets/imgs/TechnologyImg.png" alt="">
          </div>
        </span>
        <span>
          <div class="title">三维模型切片加载</div>
          <div class="img-box">
            <img src="../assets/imgs/TechnologyImg02.png" alt="">
          </div>
        </span>
        <span>
          <div class="title">一键发布模块</div>
          <div class="img-box">
            <img src="../assets/imgs/TechnologyImg03.png" alt="">
          </div>
        </span>
        <span>
          <div class="title">数字资源加密</div>
          <div class="img-box">
            <img src="../assets/imgs/TechnologyImg04.png" alt="">
          </div>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
  import { getColumnContent } from "../api/index.js";
  import store from "@/store";
  export default {
    data() {
      return {
        playerOptions: {
          playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
          autoplay: false, //如果true,浏览器准备好时开始回放。
          muted: false, // 默认情况下将会消除任何音频。
          loop: false, // 导致视频一结束就重新开始。
          preload: "auto", // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
          language: "zh-CN",
          aspectRatio: "16:9", // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
          fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
          sources: [
            {
              type: "video/mp4", // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
              src: "http://clips.vorwaerts-gmbh.de/big_buck_bunny.mp4", // url地址
            },
          ],
          poster: "", // 你的封面地址
          notSupportedMessage: "此视频暂无法播放，请稍后再试", // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
          controlBar: {
            timeDivider: true,
            durationDisplay: true,
            remainingTimeDisplay: false,
            fullscreenToggle: true, // 全屏按钮
          },
        },
        baseIP: process.env.VUE_APP_BASE_URL,
        imageShowAPI: "/yuan/files/getFileStream/",
        title: ''
      };
    },
    mounted() {
      getColumnContent({ optionType: '4' }).then((res) => {
        if (res.code === 0 && res.data.content.length > 0 && res.data.content[0].columnsContentFiles[0] != undefined) {
          this.playerOptions.sources[0].src = ''
          this.playerOptions.sources[0].src = this.baseIP + this.imageShowAPI + res.data.content[0].columnsContentFiles[0].files.uri;
          this.title = res.data.content[0].title
          this.playerOptions.poster = this.baseIP + this.imageShowAPI + res.data.content[0].contentImage.waterMarkUri
        }
      })
    },
  };
</script>

<style lang="scss" scoped>
  .gls {
    .text-title {
      width: 665px;
      // height: 120px;
      line-height: 40px;
      margin: 0 auto;
      margin-bottom: 30px;
      margin-top: 70px;
      i {
        display: block;
        font-style: normal;
        height: 40px;
        line-height: 40px;
        font-size: 34px;
        color: #333333;
      }
      p {
        line-height: 30px;
        font-size: 16px;
        color: #999999;
        margin: 20px 0;
      }
    }
    .video {
      width: 1289px;
      // height: 749px;
      position: relative;
      cursor: pointer;
      margin-bottom: 105px;
      /deep/ .video-js {
        background-color: transparent;
      }
      /deep/ .vjs-poster {
        background-size: cover;
      }
      // 隐藏功能按钮键
      /deep/ .vjs-control-bar {
        display: none;
      }
      .video-title {
        width: 100%;
        height: 40px;
        line-height: 40px;
        position: absolute;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        font-size: 22px;
        color: #ffffff;
        text-align: center;
      }
    }
    .technical {
      width: 100%;
      height: 746px;
      background-color: #eef3f9;
      .text-title {
        width: 665px;
        height: 120px;
        line-height: 120px;
        margin: 0 auto;
        padding-top: 90px;
        box-sizing: border-box;
        i {
          display: block;
          font-style: normal;
          height: 40px;
          line-height: 40px;
          font-size: 34px;
          color: #333333;
        }
        p {
          line-height: 40px;
          font-size: 16px;
          color: #999999;
          margin: 10px 0;
        }
      }
      .technical-list {
        height: 345px;
        margin-top: 117px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        span {
          display: block;
          width: 320px;
          height: 340px;
          box-sizing: border-box;
          margin-top: 20px;
          padding: 45px 20px 0 20px;
          box-sizing: border-box;
          background-color: #ffffff;
          position: relative;
          .img-box {
            width: 281px;
            height: 272px;
            img {
              width: 100%;
              height: 100%;
            }
          }
          .title {
            width: 266px;
            height: 59px;
            line-height: 59px;
            position: absolute;
            top: -25px;
            left: 28px;
            border-radius: 30px;
            font-size: 24px;
            color: #ffffff;
            background-color: #5195cf;
            text-align: center;
          }
        }
      }
    }
  }
</style>